import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
} from "@mui/material";
import { flatMap, map } from "lodash";
import { Children, forwardRef, useRef } from "react";
import ZillowCard from "../components/ZillowCard";
import SkeletonZillowCard from "../components/ZillowCard/ZillowCardSkeleton";
import Filters from "../components/ZillowFilters";
import engine from "./engine";
import { CloseRounded } from "@mui/icons-material";

function Zillow(
  { data, isLoading, hasNextPage, isSmall, searchParams, setSearchQuery },
  ref
) {
  const searchFormRef = useRef(null);
  return (
    <Box>
      <AppBar color="transparent">
        <Toolbar
          variant="dense"
          sx={{
            width: "100%",
            background: "#fff",
            py: 0,
            columnGap: 5,
            justifyContent: "space-between",
          }}
        >
          <Box width={isSmall ? "30%" : "12%"}>
            <a href="/">
              <img
                src="/logo.jpeg"
                style={{
                  padding: 2,
                  height: "100%",
                  width: "100%",
                  transitionDuration: "500ms",
                  aspectRatio: "16/9",
                  objectFit: "contain",
                }}
                alt="The Offer Sheet"
              />
            </a>
          </Box>
          <Filters />
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box sx={{ px: isSmall ? 2 : 4, pb: 5, pt: isSmall ? 3 : 6 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearchQuery({ ...searchParams, search: e.target.search.value });
            e.target.search.blur();
          }}
          ref={searchFormRef}
        >
          <TextField
            name="search"
            fullWidth
            variant="standard"
            placeholder="Search Address, City, Zip Code"
            color="primary"
            defaultValue={searchParams.search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Stack direction={"row"}>
                    {isSmall ? (
                      <IconButton
                        color="error"
                        onClick={() => {
                          searchFormRef.current.reset();
                          setSearchQuery({
                            ...searchParams,
                            search: null,
                          });
                        }}
                      >
                        <CloseRounded />
                      </IconButton>
                    ) : (
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        type="button"
                        sx={{ fontWeight: "bolder", borderWidth: 2 }}
                        onClick={() => {
                          searchFormRef.current.reset();
                          setSearchQuery({
                            ...searchParams,
                            search: null,
                          });
                        }}
                      >
                        Clear
                      </Button>
                    )}
                  </Stack>
                </InputAdornment>
              ),
            }}
            sx={{
              fontWeight: "bolder",
              "& input": {
                "&::placeholder": {
                  fontWeight: "bolder",
                  fontSize: isSmall ? "inherit" : "25px",
                  color: "primary.dark",
                  opacity: 0.2,
                },
                fontWeight: "bolder",
                fontSize: isSmall ? "inherit" : "25px",
                pb: 1,
              },
            }}
          />
        </form>
        <Grid container spacing={5} sx={{ pt: 3 }}>
          {isLoading
            ? Children.toArray(
                map([...new Array(40)], () => {
                  return (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                      <SkeletonZillowCard />;
                    </Grid>
                  );
                })
              )
            : Children.toArray(
                map(flatMap(data.pages), (item) => {
                  return (
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                      <ZillowCard {...item} />
                    </Grid>
                  );
                })
              )}
        </Grid>
      </Box>
      <Stack alignItems={"center"} justifyContent={"center"} height={100}>
        {hasNextPage ? (
          <div className="typewriter" ref={ref}>
            <div className="slide">
              <i></i>
            </div>
            <div className="paper"></div>
            <div className="keyboard"></div>
          </div>
        ) : null}
      </Stack>
    </Box>
  );
}

export default engine(forwardRef(Zillow));
