import { useFormik } from "formik";
import {
  map,
  every,
  filter,
  forEach,
  groupBy,
  includes,
  isEmpty,
  toPairs,
  without,
  sum,
} from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import supabase from "../../../supabase";
import { useScreenWidth, useSearchParams } from "../../../utils";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { callback } = props;
    const queryClient = useQueryClient();
    const { isSmall } = useScreenWidth();
    const [open, setOpen] = useState(false);
    const { searchParams, setSearchQuery, clearSearchQuery } =
      useSearchParams();

    const [render, setRender] = useState({
      beds: true,
      baths: false,
      state: false,
    });

    const {
      data: { data = [], ranges = {} } = {},
      isLoading: isFilterLoading,
    } = useQuery(["filters"], async () => {
      const data = await Promise.all([
        supabase.rpc("get_ranges"),
        supabase.rpc("get_filters_pairs"),
      ]);
      return { data: data[1].data, ranges: data[0].data };
    });

    const formik = useFormik({
      initialValues: {
        type: "",
        beds: [],
        state: [],
        baths: [],
        sortBy: "date",
        priceOfHouse: [ranges?.priceOfHouse?.min, ranges?.priceOfHouse?.max],
        monthlyExpectedIncome: [
          ranges?.monthlyExpectedIncome?.min,
          ranges?.monthlyExpectedIncome?.max,
        ],
        monthlyExpectedCashFlow: [
          ranges?.monthlyExpectedCashFlow?.min,
          ranges?.monthlyExpectedCashFlow?.max,
        ],
        expectedCashOnCashReturn: [
          ranges?.expectedCashOnCashReturn?.min,
          ranges?.expectedCashOnCashReturn?.max,
        ],
        ...searchParams,
      },
      onSubmit: (values) => {
        callback?.();
        setSearchQuery({ ...searchParams, ...values });
      },
      enableReinitialize: true,
    });

    const getFilteredOptions = (field) => {
      return map(
        toPairs(
          groupBy(
            filter(data, (row) => {
              let checks = [];
              forEach(without(["beds", "baths", "state"], field), (item) => {
                if (isEmpty(formik.values[item])) {
                  checks.push(true);
                } else {
                  includes(formik.values[item], row[item].toString())
                    ? checks.push(true)
                    : checks.push(false);
                }
              });
              return every(checks);
            }),
            field
          )
        ),
        ([key, val]) => ({
          label: key,
          value: key,
          count: sum(map(val, "count")),
        })
      );
    };

    useEffect(() => {
      setRender((prev) => ({ ...prev, baths: false, state: false }));
      setTimeout(() => {
        setRender((prev) => ({ ...prev, baths: true, state: true }));
      }, 100);
    }, [formik.values.beds]);

    useEffect(() => {
      setRender((prev) => ({ ...prev, beds: false, state: false }));
      setTimeout(() => {
        setRender((prev) => ({ ...prev, beds: true, state: true }));
      }, 100);
    }, [formik.values.baths]);

    useEffect(() => {
      setRender((prev) => ({ ...prev, beds: false, baths: false }));
      setTimeout(() => {
        setRender((prev) => ({ ...prev, beds: true, baths: true }));
      }, 100);
    }, [formik.values.state]);

    return (
      <Component
        {...props}
        {...{
          open,
          data,
          formik,
          render,
          ranges,
          isSmall,
          setOpen,
          queryClient,
          searchParams,
          setSearchQuery,
          isFilterLoading,
          clearSearchQuery,
          getFilteredOptions,
        }}
      />
    );
  };

export default engine;
