import { createTheme, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import Zillow from "./Zillow";
import "@fontsource/lato";

const queryClient = new QueryClient();

export default function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "'Lato', sans-serif",
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Zillow />
      </ThemeProvider>
    </QueryClientProvider>
  );
}
