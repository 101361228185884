import { Flare } from "@mui/icons-material";
import { Box, CardActionArea, Chip, Divider, Grid, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { map } from "lodash";
import * as React from "react";
import { toDollars, toPercentage } from "../../utils";

export default function ZillowCard(data) {
  return (
    <CardActionArea LinkComponent={"a"} href={data?.urls} target="_blank">
      <Card sx={{ borderRadius: 3 }}>
        <CardMedia
          sx={{ aspectRatio: "16/9", borderRadius: 3 }}
          image={
            data?.imageUrl ||
            `https://dummyimage.com/600x400/cccccc/fff.png&text=The Offer Sheet`
          }
          title={data?.address}
        />

        {data.type === "BONUS" && (
          <Chip
            label={"BONUS"}
            variant="filled"
            color="primary"
            size="small"
            icon={<Flare fontSize="small" />}
            sx={{ position: "absolute", top: 10, right: 10 }}
          />
        )}

        <CardContent sx={{ paddingBottom: "0.5rem!important", pt: 1 }}>
          <Typography variant="body1" noWrap fontWeight={600}>
            {data?.address}
          </Typography>

          <Stack
            direction={"row"}
            alignItems={"end"}
            justifyContent={"space-between"}
          >
            <div>
              <Typography
                gutterBottom
                variant="h5"
                my={1}
                component="div"
                fontWeight={"bolder"}
              >
                {toDollars(data?.priceOfHouse)}
              </Typography>

              <Stack
                direction={"row"}
                divider={<Box px={1}>&#x2022;</Box>}
                alignItems="center"
              >
                <Typography variant="body2" fontWeight={600}>
                  {data?.beds} Bd
                </Typography>
                <Typography variant="body2" fontWeight={600}>
                  {data?.baths} Ba
                </Typography>
                <Typography variant="body2" fontWeight={600}>
                  {data?.livingArea} Sq. ft.
                </Typography>{" "}
              </Stack>
            </div>
            <Stack spacing={0}>
              <Typography variant="caption" color="info.main" fontWeight={600}>
                Listed:
              </Typography>
              <Typography variant="body2" fontWeight={600} align="right">
                {new Date(data?.date).toLocaleDateString()}
              </Typography>
            </Stack>
          </Stack>

          <Divider sx={{ mt: 1, mb: 2 }} />
          <Grid container spacing={2}>
            {React.Children.toArray(
              map(
                [
                  {
                    label: "Revenue",
                    value: data?.monthlyExpectedIncome,
                    formatter: (val) => `${toDollars(val)}/mo`,
                  },
                  {
                    label: "Expenses",
                    value: data?.monthlyExpectedExpenses,
                    formatter: (val) => `${toDollars(val)}/mo`,
                  },
                  {
                    label: "Cash Flow",
                    value: data?.monthlyExpectedCashFlow,
                    formatter: (val) => `${toDollars(val)}/mo`,
                  },
                  {
                    label: "Expected ADR",
                    value: data?.averageDailyRate,
                    formatter: (val) => `${toDollars(val)}/mo`,
                  },
                  {
                    label: "Occ. Rate",
                    value: data?.expectedOccupancyRate,
                    formatter: toPercentage,
                  },
                  {
                    label: "Cash on Cash",
                    value: data?.expectedCashOnCashReturn,
                    formatter: toPercentage,
                  },
                ],
                ({ label, value, formatter }) => {
                  return (
                    <Grid item xs={4}>
                      <Stack>
                        <Typography
                          variant="body2"
                          color="info.main"
                          fontWeight="bolder"
                        >
                          {label}
                        </Typography>
                        <Typography variant="body1" fontWeight="bolder">
                          {formatter(value)}
                        </Typography>
                      </Stack>
                    </Grid>
                  );
                }
              )
            )}
          </Grid>
        </CardContent>
      </Card>
    </CardActionArea>
  );
}