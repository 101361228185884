import {
  Box,
  CardActionArea,
  Divider,
  Grid,
  Skeleton,
  Stack,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { map } from "lodash";
import * as React from "react";

export default function ZillowCard(data) {
  return (
    <CardActionArea disabled>
      <Card sx={{ borderRadius: 3 }}>
        <CardMedia
          sx={{ aspectRatio: "16/9", borderRadius: 3 }}
          image={`https://dummyimage.com/600x400/cccccc/fff.png&text=loading`}
        />
        <CardContent sx={{ paddingBottom: "0.5rem!important", pt: 1 }}>
          <Typography variant="body1" noWrap fontWeight={600}>
            <Skeleton animation="wave" />
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            my={1}
            component="div"
            fontWeight={"bolder"}
          >
            {data?.priceOfHouse}
          </Typography>

          <Stack
            direction={"row"}
            divider={<Box px={1}>&#x2022;</Box>}
            alignItems="center"
          >
            <Typography width={"30%"} variant="body2" fontWeight={600}>
              <Skeleton animation="wave" />
            </Typography>
            <Typography width={"30%"} variant="body2" fontWeight={600}>
              <Skeleton animation="wave" />
            </Typography>
            <Typography width={"30%"} variant="body2" fontWeight={600}>
              <Skeleton animation="wave" />
            </Typography>
          </Stack>

          <Divider sx={{ mt: 1, mb: 2 }} />
          <Grid container spacing={2}>
            {React.Children.toArray(
              map([...new Array(6)], () => {
                return (
                  <Grid item xs={4}>
                    <Stack>
                      <Typography fontWeight={600} pr={2}>
                        <Skeleton animation="wave" />
                      </Typography>
                      <Typography fontWeight={600}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })
            )}
          </Grid>
        </CardContent>
      </Card>
    </CardActionArea>
  );
}
