import { forEach, includes, isArray, omit, toPairs } from "lodash";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import supabase, { SUPABASE } from "../supabase";
import { rangeFields, useScreenWidth, useSearchParams } from "../utils";

const PAGE_SIZE = 40;

const engine =
  (Component) =>
  ({ ...props }) => {
    const { ref, inView } = useInView();
    const { searchParams, setSearchQuery } = useSearchParams();
    const { isSmall } = useScreenWidth();

    const {
      data,
      isLoading,
      fetchNextPage,
      isFetchingNextPage,
      hasNextPage = true,
    } = useInfiniteQuery(
      ["zillow", searchParams],
      async ({ pageParam = 0 }) => {
        if (pageParam === 0) window.scrollTo(0, 0);

        const from = pageParam;
        const to = pageParam + (PAGE_SIZE - 1);

        let query = supabase
          .from(SUPABASE.TABLE)
          .select("*")
          .range(from, to)
          .order(searchParams.sortBy || "date", { ascending: false });

        forEach(
          toPairs(omit(searchParams, ["sortBy", "search"])),
          ([key, val]) => {
            if (includes(rangeFields, key)) {
              query.gte(key, val[0]).lte(key, val[1]);
              return;
            }
            if (isArray(val)) {
              query.in(key, val);
              return;
            }
            query.eq(key, val);
          }
        );

        if (searchParams.search)
          query.ilike("address", `%${searchParams.search}%`);

        const { data, error } = await query;

        if (error) {
          throw new Error(error.message);
        }

        return data;
      },
      {
        getNextPageParam: (lastPage, allPages) => {
          return lastPage.length > 0 ? allPages.length * PAGE_SIZE : undefined;
        },
      }
    );

    useEffect(() => {
      if (inView) {
        fetchNextPage();
      }
    }, [inView]);

    return (
      <Component
        {...props}
        {...{
          isLoading,
          data,
          ref,
          inView,
          isFetchingNextPage,
          hasNextPage,
          isSmall,
          searchParams,
          setSearchQuery,
        }}
      />
    );
  };

export default engine;

// const rangeFilters = pick(searchParams, rangeFields);
// const filters = compact([
// ...flatMap(
//   map(toPairs(rangeFilters), ([key, val]) => [
//     orderBy(key),
//     where(key, ">=", val[0]),
//     where(key, "<=", val[1]),
//   ])
// ),
//   limit(41),
//   orderBy(searchParams.sortBy || "date", "desc"),
//   pageParam && startAt(pageParam),
//   ...map(
//     toPairs(omit(searchParams, ["sortBy", ...rangeFields])),
//     ([key, val]) =>
//       isArray(val) ? where(key, "in", val) : where(key, "==", val)
//   ),
// ]);

// const q = query(collection(database, "ZILLOW"), ...filters);
// const querySnapshot = await getDocs(q);
// let docs = [];
// querySnapshot.forEach((doc) => {
//   docs.push(doc.data());
// });
// return {
//   data: size(docs) === 41 ? dropRight(docs) : docs,
//   lastDocSnap:
//     size(docs) === 41
//       ? querySnapshot.docs[querySnapshot.docs.length - 1]
//       : undefined,
// };

// [
//   {
//     "zillowId": 66507726,
//     "address": "2549 State Route 29, Greenwich, NY 12834",
//     "date": "2023-05-18",
//     "imageUrl": "https://photos.zillowstatic.com/fp/2c022e2a78d1f0d96d12a2c094db4cf8-cc_ft_576.webp",
//     "priceOfHouse": "450000",
//     "beds": "3",
//     "baths": "2",
//     "type": "NEWSLETTER",
//     "urls": "https://www.zillow.com/homedetails/2549-State-Route-29-Greenwich-NY-12834/66507726_zpid/",
//     "state": "NY",
//     "monthlyExpectedCashFlow": "1213",
//     "monthlyExpectedExpenses": "4062",
//     "monthlyExpectedIncome": "5275",
//     "expectedCashOnCashReturn": "0.116"
// },
// {
//     "zillowId": 52148784,
//     "address": "337 Owassa Dr, Pocono Lake, PA 18347",
//     "date": "2023-05-18",
//     "imageUrl": "https://photos.zillowstatic.com/fp/3bad46f40f6ea9af4b111267656930d5-cc_ft_576.webp",
//     "priceOfHouse": "575000",
//     "beds": "4",
//     "baths": "4",
//     "type": "NEWSLETTER",
//     "urls": "https://www.zillow.com/homedetails/337-Owassa-Dr-Pocono-Lake-PA-18347/52148784_zpid/",
//     "state": "PA",
//     "monthlyExpectedCashFlow": "1592",
//     "monthlyExpectedExpenses": "5017",
//     "monthlyExpectedIncome": "6609",
//     "expectedCashOnCashReturn": "0.122"
// },
// {
//     "zillowId": 219442004,
//     "address": "60 Lewis Ln, Terra Alta, WV 26764",
//     "date": "2023-05-18",
//     "imageUrl": "https://photos.zillowstatic.com/fp/d5514786e8f5d6baede34e339cfedc85-cc_ft_576.webp",
//     "priceOfHouse": "299000",
//     "beds": "3",
//     "baths": "2",
//     "type": "NEWSLETTER",
//     "urls": "https://www.zillow.com/homedetails/60-Lewis-Ln-Terra-Alta-WV-26764/219442004_zpid/",
//     "state": "WV",
//     "monthlyExpectedCashFlow": "919",
//     "monthlyExpectedExpenses": "2750",
//     "monthlyExpectedIncome": "3668",
//     "expectedCashOnCashReturn": "0.126"
// },
// {
//     "zillowId": 2057601928,
//     "address": "179 Gates Road, Jefferson, ME 04348",
//     "date": "2023-05-18",
//     "imageUrl": "https://photos.zillowstatic.com/fp/e4a2a2d949a162b6af83797e1c60ab5e-cc_ft_576.webp",
//     "priceOfHouse": "415000",
//     "beds": "3",
//     "baths": "2",
//     "type": "NEWSLETTER",
//     "urls": "https://www.zillow.com/homedetails/179-Gates-Rd-Jefferson-ME-04348/2057601928_zpid/",
//     "state": "ME",
//     "monthlyExpectedCashFlow": "1230",
//     "monthlyExpectedExpenses": "3830",
//     "monthlyExpectedIncome": "5061",
//     "expectedCashOnCashReturn": "0.126"
// },
// {
//     "zillowId": 17420946,
//     "address": "1204 Jungfrau Dr, Crestline, CA 92325",
//     "date": "2023-05-18",
//     "imageUrl": "https://photos.zillowstatic.com/fp/89e5620d905c8d7be9669d637ea10320-cc_ft_576.webp",
//     "priceOfHouse": "474000",
//     "beds": "2",
//     "baths": "2",
//     "type": "NEWSLETTER",
//     "urls": "https://www.zillow.com/homedetails/1204-Jungfrau-Dr-Crestline-CA-92325/17420946_zpid/",
//     "state": "CA",
//     "monthlyExpectedCashFlow": "1445",
//     "monthlyExpectedExpenses": "4140",
//     "monthlyExpectedIncome": "5585",
//     "expectedCashOnCashReturn": "0.132"
// },
// {
//     "zillowId": 84894471,
//     "address": "68 Egypt Road, Raymond, ME 04071",
//     "date": "2023-05-18",
//     "imageUrl": "https://photos.zillowstatic.com/fp/3e2fdb54e566a49439279e423ccb0a3c-cc_ft_576.webp",
//     "priceOfHouse": "495000",
//     "beds": "4",
//     "baths": "2",
//     "type": "NEWSLETTER",
//     "urls": "https://www.zillow.com/homedetails/68-Egypt-Rd-Raymond-ME-04071/84894471_zpid/",
//     "state": "ME",
//     "monthlyExpectedCashFlow": "1183",
//     "monthlyExpectedExpenses": "4154",
//     "monthlyExpectedIncome": "5337",
//     "expectedCashOnCashReturn": "0.104"
// },
// {
//     "zillowId": 2057607811,
//     "address": "3175 Middle Creek Rd, Afton, TN 37616",
//     "date": "2023-05-18",
//     "imageUrl": "https://photos.zillowstatic.com/fp/91ac6b610096e8f69bd163827c86043d-cc_ft_576.webp",
//     "priceOfHouse": "375000",
//     "beds": "3",
//     "baths": "2",
//     "type": "NEWSLETTER",
//     "urls": "https://www.zillow.com/homedetails/3175-Middle-Creek-Rd-Afton-TN-37616/2057607811_zpid/",
//     "state": "TN",
//     "monthlyExpectedCashFlow": "1391",
//     "monthlyExpectedExpenses": "3359",
//     "monthlyExpectedIncome": "4750",
//     "expectedCashOnCashReturn": "0.156"
// },
// {
//     "zillowId": 123598899,
//     "address": "148 Dekker Drive, Golden, CO 80401",
//     "date": "2023-05-18",
//     "imageUrl": "https://photos.zillowstatic.com/fp/82732f4d2105fd5fbdc65d902a0304d8-cc_ft_1536.webp",
//     "priceOfHouse": "1850000",
//     "beds": "5",
//     "baths": "5",
//     "type": "NEWSLETTER",
//     "urls": "https://www.zillow.com/homedetails/148-Dekker-Dr-Golden-CO-80401/123598899_zpid/",
//     "state": "CO",
//     "monthlyExpectedCashFlow": "3359",
//     "monthlyExpectedExpenses": "12119",
//     "monthlyExpectedIncome": "15478",
//     "expectedCashOnCashReturn": "0.085"
// },
// {
//     "zillowId": 2099578065,
//     "address": "116 Levenseller Road, Atkinson, ME 04426",
//     "date": "2023-05-18",
//     "imageUrl": "https://photos.zillowstatic.com/fp/6517e7ed89e294b888332e08458dbf8f-cc_ft_576.webp",
//     "priceOfHouse": "119900",
//     "beds": "2",
//     "baths": "1",
//     "type": "NEWSLETTER",
//     "urls": "https://www.zillow.com/homedetails/116-Levenseller-Rd-Atkinson-ME-04426/2099578065_zpid/",
//     "state": "ME",
//     "monthlyExpectedCashFlow": "1218",
//     "monthlyExpectedExpenses": "1944",
//     "monthlyExpectedIncome": "3162",
//     "expectedCashOnCashReturn": "0.34"
// },
// {
//     "zillowId": 66882810,
//     "address": "7 Freds Rd, Denning, NY 12725",
//     "date": "2023-05-18",
//     "imageUrl": "https://photos.zillowstatic.com/fp/6fa9219615bdbd46d843cd8685088c3e-cc_ft_576.webp",
//     "priceOfHouse": "799000",
//     "beds": "3",
//     "baths": "1",
//     "type": "NEWSLETTER",
//     "urls": "https://www.zillow.com/homedetails/7-Freds-Rd-Denning-NY-12725/66882810_zpid/",
//     "state": "NY",
//     "monthlyExpectedCashFlow": "1226",
//     "monthlyExpectedExpenses": "5928",
//     "monthlyExpectedIncome": "7154",
//     "expectedCashOnCashReturn": "0.069"
// }
// ]
