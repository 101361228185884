import { TuneRounded } from "@mui/icons-material";
import {
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import * as React from "react";
import { toDollars, toPercentage, useScreenWidth } from "../../utils";
import ZillowRangeSlider from "../ZillowRangeSlider";

export default function AdditionalFilters({ ranges }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const { isSmall } = useScreenWidth();

  const sliders = (
    <Stack spacing={3} width={"100%"}>
      <ZillowRangeSlider
        min={ranges?.monthlyExpectedIncome?.min}
        max={ranges?.monthlyExpectedIncome?.max}
        name={"monthlyExpectedIncome"}
        label={"Monthly Expected Income"}
        labelFormat={toDollars}
      />

      <ZillowRangeSlider
        min={ranges?.monthlyExpectedCashFlow?.min}
        max={ranges?.monthlyExpectedCashFlow?.max}
        name={"monthlyExpectedCashFlow"}
        label={"Monthly Expected Cash Flow"}
        labelFormat={toDollars}
      />

      <ZillowRangeSlider
        min={ranges?.expectedCashOnCashReturn?.min}
        max={ranges?.expectedCashOnCashReturn?.max}
        name={"expectedCashOnCashReturn"}
        label={"Cash on Cash Return"}
        step={0.01}
        labelFormat={toPercentage}
      />
    </Stack>
  );

  if (isSmall) return sliders;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Tooltip title="Additional Filters" arrow>
          <IconButton
            color="primary"
            type="button"
            onClick={handleClick}
            sx={{
              border: "2px solid",
              borderColor: "primary.main",
              borderRadius: 1,
              py: 0.7,
            }}
          >
            <TuneRounded />
          </IconButton>
        </Tooltip>
        {/* <Button
          variant="outlined"
          color="primary"
          startIcon={<TuneRounded />}
          sx={{
            borderRadius: "50px",
            fontWeight: 600,
            textTransform: "capitalize",
            borderWidth: 2,
            "&:hover": {
              borderWidth: 2,
            },
          }}
          aria-describedby={id}
          type="button"
          onClick={handleClick}
        >
          Additional&nbsp;Filters
        </Button> */}
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          sx={{ width: "30%", zIndex: 999999, pr: '1rem!important' }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={350}>
              <Paper
                elevation={4}
                sx={{
                  borderRadius: 3,
                  overflow: "hidden",
                  mt: 2,
                }}
              >
                <Box sx={{ p: 2, pt: 3 }}>{sliders}</Box>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
