import { Box, Chip, Slider, Stack, Typography } from "@mui/material";
import { Field } from "formik";
import React, { Fragment } from "react";

function ZillowRangeSlider({ name, label, labelFormat, boxProps, ...props }) {
  return (
    <Box {...boxProps}>
      <Field name={name}>
        {({ field }) => (
          <Fragment>
            <Typography fontWeight={600} variant="body1" align="center">
              {label}
            </Typography>
            <Stack direction="row" justifyContent={"space-between"} spacing={2}>
              <Chip
                variant="outlined"
                sx={{
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                color="primary"
                label={labelFormat(field.value[0])}
              />
              <Slider
                disableSwap
                valueLabelDisplay="off"
                {...props}
                {...field}
              />
              <Chip
                variant="outlined"
                sx={{
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                color="primary"
                label={labelFormat(field.value[1])}
              />
            </Stack>
          </Fragment>
        )}
      </Field>
    </Box>
  );
}

export default ZillowRangeSlider;
