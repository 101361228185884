import React, { useState } from "react";
import { useScreenWidth } from "../../utils";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { isSmall } = useScreenWidth();
    const [open, setOpen] = useState(false);

    return (
      <Component
        {...props}
        {...{
          isSmall,
          open,
          setOpen,
        }}
      />
    );
  };

export default engine;
