import { useMediaQuery, useTheme } from "@mui/material";
import {
  groupBy,
  includes,
  isArray,
  isEmpty,
  map,
  mapValues,
  max,
  min,
  pickBy,
  sumBy,
} from "lodash";
import { create } from "zustand";

export const rangeFields = [
  "expectedCashOnCashReturn",
  "monthlyExpectedCashFlow",
  "monthlyExpectedIncome",
  "priceOfHouse",
];

export const getMin = (data) =>
  min(map(data, (item) => Number(item?.toString()?.replace(/[^0-9.-]+/g, ""))));

export const getMax = (data) =>
  max(map(data, (item) => Number(item?.toString()?.replace(/[^0-9.-]+/g, ""))));

const toNumber = (val) => Number(val?.toString()?.replace(/[^0-9.-]+/g, ""));

export const toDollars = (val) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(Number(val?.toString().replace(/[^0-9.-]+/g, "")));

export const toPercentage = (val) => `${Number((val * 100).toFixed(2))}%`;

export function aggregateData(data) {
  const aggregatedData = groupBy(data, "value");
  return map(aggregatedData, (group) => ({
    label: group[0].label,
    value: group[0].value,
    count: sumBy(group, "count"),
  }));
}

export const useSearchParams = create((set, get) => {
  return {
    searchParams: mapValues(
      Object.fromEntries(new URLSearchParams(window.location.search).entries()),
      (val) => (includes(val, "[") ? JSON.parse(val) : val)
    ),
    setSearchQuery: (params) => {
      params = pickBy(params, (val) => !isEmpty(val));
      set({ searchParams: params });
      params = mapValues(params, (val) =>
        isArray(val) ? JSON.stringify(val) : val
      );
      params = new URLSearchParams(params);
      const url = `${window.location.pathname}?${params.toString()}`;
      window.history.pushState({ path: url }, "", url);
    },
    clearSearchQuery: () => {
      set({ searchParams: {} });
      const currentUrl = new URL(window.location.href);
      currentUrl.search = "";
      window.history.replaceState({}, "", currentUrl.href);
    },
  };
});

export function useScreenWidth() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));

  return { isSmall };
}
