import { createClient } from "@supabase/supabase-js";

export const SUPABASE = {
  PROJECT_ID: "kzbvxknrcvovzumgarxm",
  TABLE: "zillow",
  API_KEY:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt6YnZ4a25yY3Zvdnp1bWdhcnhtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODU4MTY1NDYsImV4cCI6MjAwMTM5MjU0Nn0.RmaWwEX-mHZGimUPz6RLBTNhzpF_khGQ7Ey1cteZHGk",
};

const supabase = createClient(
  `https://${SUPABASE.PROJECT_ID}.supabase.co`,
  SUPABASE.API_KEY
);

export default supabase;