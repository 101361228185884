import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Form, FormikProvider } from "formik";
import React from "react";
import { toDollars } from "../../../utils";
import ZillowSelect from "../../ZilliowSelect";
import ZillowRangeSlider from "../../ZillowRangeSlider";
import engine from "./engine";
import AdditionalFilters from "../../AdditionalFilters";
import { sortBy } from "lodash";

function FilterForm({
  ranges,
  formik,
  render,
  isSmall,
  callback,
  isFilterLoading,
  getFilteredOptions,
  setSearchQuery,
  searchParams,
}) {
  return (
    <Box width={"100%"} pb={isSmall ? 10 : 0}>
      {!isFilterLoading ? (
        <FormikProvider value={formik}>
          <Form>
            <Grid container alignItems={"center"} spacing={3}>
              <Grid item md={4} xs={12}>
                <ZillowRangeSlider
                  step={10000}
                  label="Price"
                  name={"priceOfHouse"}
                  labelFormat={toDollars}
                  min={ranges?.priceOfHouse?.min}
                  max={ranges?.priceOfHouse?.max}
                />
              </Grid>
              <Grid item md={8} xs={12}>
                <Stack
                  direction={isSmall ? "column" : "row"}
                  alignItems={"center"}
                  spacing={2}
                >
                  {render.beds ? (
                    <ZillowSelect
                      label="Beds"
                      name="beds"
                      multiple
                      options={getFilteredOptions("beds")}
                    />
                  ) : (
                    <TextField
                      variant="outlined"
                      value={formik.values.beds.join(",")}
                      size="small"
                      label="Beds"
                      fullWidth
                    />
                  )}

                  {render.baths ? (
                    <ZillowSelect
                      label="Baths"
                      name="baths"
                      multiple
                      options={getFilteredOptions("baths")}
                    />
                  ) : (
                    <TextField
                      variant="outlined"
                      value={formik.values.baths.join(",")}
                      size="small"
                      label="Baths"
                      fullWidth
                    />
                  )}

                  {render.state ? (
                    <ZillowSelect
                      label="States"
                      name="state"
                      multiple
                      options={sortBy(getFilteredOptions("state"), "value")}
                    />
                  ) : (
                    <TextField
                      variant="outlined"
                      value={formik.values.state.join(",")}
                      size="small"
                      label="States"
                      fullWidth
                    />
                  )}

                  <ZillowSelect
                    label="Sort By"
                    name="sortBy"
                    options={[
                      { label: "Newest", value: "date" },
                      {
                        label: "Monthly Expected Income",
                        value: "monthlyExpectedIncome",
                      },
                      {
                        label: "Monthly Expected Cash Flow",
                        value: "monthlyExpectedCashFlow",
                      },
                      {
                        label: "Cash on Cash Return",
                        value: "expectedCashOnCashReturn",
                      },
                    ]}
                  />
                  <ZillowSelect
                    label="Type"
                    name="type"
                    options={[
                      { label: "Newsletter", value: "NEWSLETTER" },
                      { label: "Bonus", value: "BONUS" },
                    ]}
                  />

                  <AdditionalFilters {...{ ranges }} />

                  <Stack
                    direction="row"
                    width={isSmall ? "100%" : "fit-content"}
                    py={isSmall ? 2 : 0}
                    spacing={2}
                    justifyContent={isSmall ? "center" : "unset"}
                    sx={
                      isSmall
                        ? { position: "fixed", bottom: 0, background: "#fff" }
                        : {}
                    }
                  >
                    <Button
                      variant="contained"
                      color="error"
                      type="button"
                      sx={{ fontWeight: "500", py: 0.7 }}
                      disableElevation
                      onClick={() => {
                        callback?.();
                        setSearchQuery({ search: searchParams.search });
                        formik?.resetForm();
                      }}
                      size="small"
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={{ fontWeight: "500" }}
                      disableElevation
                      size="small"
                    >
                      Apply
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      ) : (
        <Stack width="100%" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
    </Box>
  );
}

export default engine(FilterForm);
