import { TuneRounded } from "@mui/icons-material";
import { Container, Drawer, IconButton } from "@mui/material";
import React, { Fragment } from "react";
import FilterForm from "./FilterForm";
import engine from "./engine";

function ZillowFilters({ isSmall, open, setOpen }) {
  if (isSmall) {
    return (
      <Fragment>
        <IconButton color="primary" onClick={() => setOpen(true)}>
          <TuneRounded fontSize="large" />
        </IconButton>
        <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
          <Container sx={{ py: 2 }}>
            <FilterForm callback={() => setOpen(false)} />
          </Container>
        </Drawer>
      </Fragment>
    );
  }

  return <FilterForm />;
}

export default engine(ZillowFilters);
