import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { FastField } from "formik";
import { find } from "lodash";
import React, { Children, memo } from "react";

function ZillowSelect({ options, label, multiple, name, disabled }) {
  return (
    <FastField name={name}>
      {({ field, form: { touched, errors }, meta }) => (
        <FormControl fullWidth size="small" disabled={disabled}>
          <InputLabel>{label}</InputLabel>
          <Select
            size="small"
            {...{ ...field, label, name, multiple, disabled }}
            renderValue={(selected = []) => {
              return multiple
                ? selected.join(", ")
                : find(options, { value: selected }).label;
            }}
            MenuProps={{
              PaperProps: {
                sx: { minWidth: "150px!important", maxWidth: "none" },
              },
            }}
            error={!!(touched[name] && errors[name])}
          >
            {Children.toArray(
              options.map(({ count, value: val, label }) => (
                <MenuItem
                  key={val}
                  value={val}
                  sx={{ pl: multiple && 0, py: multiple && 0 }}
                  disableRipple
                  disableTouchRipple
                >
                  {multiple && (
                    <ListItemIcon>
                      <Checkbox
                        size="small"
                        checked={field?.value.includes(val)}
                      />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={label} />
                  {count && (
                    <ListItemSecondaryAction>
                      <Chip
                        label={count}
                        size="small"
                        color="default"
                        sx={{ borderRadius: "8px", fontWeight: 600 }}
                      />
                    </ListItemSecondaryAction>
                  )}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      )}
    </FastField>
  );
}

export default ZillowSelect;
